import { default as _91token_93Q14LTM3dePMeta } from "/home/forge/postpolish.com/pages/auth/reset/password/[token].vue?macro=true";
import { default as indexalMwQRRP6aMeta } from "/home/forge/postpolish.com/pages/auth/verify/email/index.vue?macro=true";
import { default as indexjxurhsAtcxMeta } from "/home/forge/postpolish.com/pages/automations/create/index.vue?macro=true";
import { default as indexyUsXA82eK0Meta } from "/home/forge/postpolish.com/pages/automations/index.vue?macro=true";
import { default as indexAs71jT4wBsMeta } from "/home/forge/postpolish.com/pages/billing/index.vue?macro=true";
import { default as indexm0XSSQfOfgMeta } from "/home/forge/postpolish.com/pages/cookie-policy/index.vue?macro=true";
import { default as indexwNY2ybiEc3Meta } from "/home/forge/postpolish.com/pages/domains/create/index.vue?macro=true";
import { default as indexbzB4xF18o8Meta } from "/home/forge/postpolish.com/pages/domains/index.vue?macro=true";
import { default as indexR8bXTPi4S6Meta } from "/home/forge/postpolish.com/pages/feeds/create/index.vue?macro=true";
import { default as indexwv0IKlBD7JMeta } from "/home/forge/postpolish.com/pages/feeds/edit/[[id]]/index.vue?macro=true";
import { default as indexh14gcdPCJCMeta } from "/home/forge/postpolish.com/pages/feeds/index.vue?macro=true";
import { default as indexXDHa8V59bnMeta } from "/home/forge/postpolish.com/pages/forgot/password/index.vue?macro=true";
import { default as indexUMrCYUvzEeMeta } from "/home/forge/postpolish.com/pages/impersonate/index.vue?macro=true";
import { default as indexeBkEvqB51DMeta } from "/home/forge/postpolish.com/pages/import/index.vue?macro=true";
import { default as index10h82XJb64Meta } from "/home/forge/postpolish.com/pages/index.vue?macro=true";
import { default as indexB7gKKcHfP8Meta } from "/home/forge/postpolish.com/pages/links/create/index.vue?macro=true";
import { default as indexqaOhBOjNHdMeta } from "/home/forge/postpolish.com/pages/links/index.vue?macro=true";
import { default as indexl1JS1WBVTkMeta } from "/home/forge/postpolish.com/pages/login/index.vue?macro=true";
import { default as indexudL0rcdQnqMeta } from "/home/forge/postpolish.com/pages/privacy-policy/index.vue?macro=true";
import { default as indexIa9wy5lZA1Meta } from "/home/forge/postpolish.com/pages/profile/index.vue?macro=true";
import { default as indexsosIZWZx85Meta } from "/home/forge/postpolish.com/pages/register/index.vue?macro=true";
import { default as indexmsVHnCEDvxMeta } from "/home/forge/postpolish.com/pages/return-and-refund-policy/index.vue?macro=true";
import { default as indexYNfFEKzJloMeta } from "/home/forge/postpolish.com/pages/subscribe/index.vue?macro=true";
import { default as indexwXJzbepGzbMeta } from "/home/forge/postpolish.com/pages/terms-and-conditions/index.vue?macro=true";
export default [
  {
    name: _91token_93Q14LTM3dePMeta?.name ?? "auth-reset-password-token",
    path: _91token_93Q14LTM3dePMeta?.path ?? "/auth/reset/password/:token()",
    meta: _91token_93Q14LTM3dePMeta || {},
    alias: _91token_93Q14LTM3dePMeta?.alias || [],
    redirect: _91token_93Q14LTM3dePMeta?.redirect,
    component: () => import("/home/forge/postpolish.com/pages/auth/reset/password/[token].vue").then(m => m.default || m)
  },
  {
    name: indexalMwQRRP6aMeta?.name ?? "auth-verify-email",
    path: indexalMwQRRP6aMeta?.path ?? "/auth/verify/email",
    meta: indexalMwQRRP6aMeta || {},
    alias: indexalMwQRRP6aMeta?.alias || [],
    redirect: indexalMwQRRP6aMeta?.redirect,
    component: () => import("/home/forge/postpolish.com/pages/auth/verify/email/index.vue").then(m => m.default || m)
  },
  {
    name: indexjxurhsAtcxMeta?.name ?? "automations-create",
    path: indexjxurhsAtcxMeta?.path ?? "/automations/create",
    meta: indexjxurhsAtcxMeta || {},
    alias: indexjxurhsAtcxMeta?.alias || [],
    redirect: indexjxurhsAtcxMeta?.redirect,
    component: () => import("/home/forge/postpolish.com/pages/automations/create/index.vue").then(m => m.default || m)
  },
  {
    name: indexyUsXA82eK0Meta?.name ?? "automations",
    path: indexyUsXA82eK0Meta?.path ?? "/automations",
    meta: indexyUsXA82eK0Meta || {},
    alias: indexyUsXA82eK0Meta?.alias || [],
    redirect: indexyUsXA82eK0Meta?.redirect,
    component: () => import("/home/forge/postpolish.com/pages/automations/index.vue").then(m => m.default || m)
  },
  {
    name: indexAs71jT4wBsMeta?.name ?? "billing",
    path: indexAs71jT4wBsMeta?.path ?? "/billing",
    meta: indexAs71jT4wBsMeta || {},
    alias: indexAs71jT4wBsMeta?.alias || [],
    redirect: indexAs71jT4wBsMeta?.redirect,
    component: () => import("/home/forge/postpolish.com/pages/billing/index.vue").then(m => m.default || m)
  },
  {
    name: indexm0XSSQfOfgMeta?.name ?? "cookie-policy",
    path: indexm0XSSQfOfgMeta?.path ?? "/cookie-policy",
    meta: indexm0XSSQfOfgMeta || {},
    alias: indexm0XSSQfOfgMeta?.alias || [],
    redirect: indexm0XSSQfOfgMeta?.redirect,
    component: () => import("/home/forge/postpolish.com/pages/cookie-policy/index.vue").then(m => m.default || m)
  },
  {
    name: indexwNY2ybiEc3Meta?.name ?? "domains-create",
    path: indexwNY2ybiEc3Meta?.path ?? "/domains/create",
    meta: indexwNY2ybiEc3Meta || {},
    alias: indexwNY2ybiEc3Meta?.alias || [],
    redirect: indexwNY2ybiEc3Meta?.redirect,
    component: () => import("/home/forge/postpolish.com/pages/domains/create/index.vue").then(m => m.default || m)
  },
  {
    name: indexbzB4xF18o8Meta?.name ?? "domains",
    path: indexbzB4xF18o8Meta?.path ?? "/domains",
    meta: indexbzB4xF18o8Meta || {},
    alias: indexbzB4xF18o8Meta?.alias || [],
    redirect: indexbzB4xF18o8Meta?.redirect,
    component: () => import("/home/forge/postpolish.com/pages/domains/index.vue").then(m => m.default || m)
  },
  {
    name: indexR8bXTPi4S6Meta?.name ?? "feeds-create",
    path: indexR8bXTPi4S6Meta?.path ?? "/feeds/create",
    meta: indexR8bXTPi4S6Meta || {},
    alias: indexR8bXTPi4S6Meta?.alias || [],
    redirect: indexR8bXTPi4S6Meta?.redirect,
    component: () => import("/home/forge/postpolish.com/pages/feeds/create/index.vue").then(m => m.default || m)
  },
  {
    name: indexwv0IKlBD7JMeta?.name ?? "feeds-edit-id",
    path: indexwv0IKlBD7JMeta?.path ?? "/feeds/edit/:id?",
    meta: indexwv0IKlBD7JMeta || {},
    alias: indexwv0IKlBD7JMeta?.alias || [],
    redirect: indexwv0IKlBD7JMeta?.redirect,
    component: () => import("/home/forge/postpolish.com/pages/feeds/edit/[[id]]/index.vue").then(m => m.default || m)
  },
  {
    name: indexh14gcdPCJCMeta?.name ?? "feeds",
    path: indexh14gcdPCJCMeta?.path ?? "/feeds",
    meta: indexh14gcdPCJCMeta || {},
    alias: indexh14gcdPCJCMeta?.alias || [],
    redirect: indexh14gcdPCJCMeta?.redirect,
    component: () => import("/home/forge/postpolish.com/pages/feeds/index.vue").then(m => m.default || m)
  },
  {
    name: indexXDHa8V59bnMeta?.name ?? "forgot-password",
    path: indexXDHa8V59bnMeta?.path ?? "/forgot/password",
    meta: indexXDHa8V59bnMeta || {},
    alias: indexXDHa8V59bnMeta?.alias || [],
    redirect: indexXDHa8V59bnMeta?.redirect,
    component: () => import("/home/forge/postpolish.com/pages/forgot/password/index.vue").then(m => m.default || m)
  },
  {
    name: indexUMrCYUvzEeMeta?.name ?? "impersonate",
    path: indexUMrCYUvzEeMeta?.path ?? "/impersonate",
    meta: indexUMrCYUvzEeMeta || {},
    alias: indexUMrCYUvzEeMeta?.alias || [],
    redirect: indexUMrCYUvzEeMeta?.redirect,
    component: () => import("/home/forge/postpolish.com/pages/impersonate/index.vue").then(m => m.default || m)
  },
  {
    name: indexeBkEvqB51DMeta?.name ?? "import",
    path: indexeBkEvqB51DMeta?.path ?? "/import",
    meta: indexeBkEvqB51DMeta || {},
    alias: indexeBkEvqB51DMeta?.alias || [],
    redirect: indexeBkEvqB51DMeta?.redirect,
    component: () => import("/home/forge/postpolish.com/pages/import/index.vue").then(m => m.default || m)
  },
  {
    name: index10h82XJb64Meta?.name ?? "index",
    path: index10h82XJb64Meta?.path ?? "/",
    meta: index10h82XJb64Meta || {},
    alias: index10h82XJb64Meta?.alias || [],
    redirect: index10h82XJb64Meta?.redirect,
    component: () => import("/home/forge/postpolish.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexB7gKKcHfP8Meta?.name ?? "links-create",
    path: indexB7gKKcHfP8Meta?.path ?? "/links/create",
    meta: indexB7gKKcHfP8Meta || {},
    alias: indexB7gKKcHfP8Meta?.alias || [],
    redirect: indexB7gKKcHfP8Meta?.redirect,
    component: () => import("/home/forge/postpolish.com/pages/links/create/index.vue").then(m => m.default || m)
  },
  {
    name: indexqaOhBOjNHdMeta?.name ?? "links",
    path: indexqaOhBOjNHdMeta?.path ?? "/links",
    meta: indexqaOhBOjNHdMeta || {},
    alias: indexqaOhBOjNHdMeta?.alias || [],
    redirect: indexqaOhBOjNHdMeta?.redirect,
    component: () => import("/home/forge/postpolish.com/pages/links/index.vue").then(m => m.default || m)
  },
  {
    name: indexl1JS1WBVTkMeta?.name ?? "login",
    path: indexl1JS1WBVTkMeta?.path ?? "/login",
    meta: indexl1JS1WBVTkMeta || {},
    alias: indexl1JS1WBVTkMeta?.alias || [],
    redirect: indexl1JS1WBVTkMeta?.redirect,
    component: () => import("/home/forge/postpolish.com/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexudL0rcdQnqMeta?.name ?? "privacy-policy",
    path: indexudL0rcdQnqMeta?.path ?? "/privacy-policy",
    meta: indexudL0rcdQnqMeta || {},
    alias: indexudL0rcdQnqMeta?.alias || [],
    redirect: indexudL0rcdQnqMeta?.redirect,
    component: () => import("/home/forge/postpolish.com/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: indexIa9wy5lZA1Meta?.name ?? "profile",
    path: indexIa9wy5lZA1Meta?.path ?? "/profile",
    meta: indexIa9wy5lZA1Meta || {},
    alias: indexIa9wy5lZA1Meta?.alias || [],
    redirect: indexIa9wy5lZA1Meta?.redirect,
    component: () => import("/home/forge/postpolish.com/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexsosIZWZx85Meta?.name ?? "register",
    path: indexsosIZWZx85Meta?.path ?? "/register",
    meta: indexsosIZWZx85Meta || {},
    alias: indexsosIZWZx85Meta?.alias || [],
    redirect: indexsosIZWZx85Meta?.redirect,
    component: () => import("/home/forge/postpolish.com/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexmsVHnCEDvxMeta?.name ?? "return-and-refund-policy",
    path: indexmsVHnCEDvxMeta?.path ?? "/return-and-refund-policy",
    meta: indexmsVHnCEDvxMeta || {},
    alias: indexmsVHnCEDvxMeta?.alias || [],
    redirect: indexmsVHnCEDvxMeta?.redirect,
    component: () => import("/home/forge/postpolish.com/pages/return-and-refund-policy/index.vue").then(m => m.default || m)
  },
  {
    name: indexYNfFEKzJloMeta?.name ?? "subscribe",
    path: indexYNfFEKzJloMeta?.path ?? "/subscribe",
    meta: indexYNfFEKzJloMeta || {},
    alias: indexYNfFEKzJloMeta?.alias || [],
    redirect: indexYNfFEKzJloMeta?.redirect,
    component: () => import("/home/forge/postpolish.com/pages/subscribe/index.vue").then(m => m.default || m)
  },
  {
    name: indexwXJzbepGzbMeta?.name ?? "terms-and-conditions",
    path: indexwXJzbepGzbMeta?.path ?? "/terms-and-conditions",
    meta: indexwXJzbepGzbMeta || {},
    alias: indexwXJzbepGzbMeta?.alias || [],
    redirect: indexwXJzbepGzbMeta?.redirect,
    component: () => import("/home/forge/postpolish.com/pages/terms-and-conditions/index.vue").then(m => m.default || m)
  }
]